import {NumberFormat} from "@coders-tm/vue-number-format";

export function numberFormat(value, {
    prefix = '',
    suffix = '',
    separator = ' ',
    decimal = '.',
    precision = 0,
    min = -Infinity,
    max = Infinity,
    nullValue = '',
    reverseFill = false,
    autofocus = false
} = {}) {
    let formatter = new NumberFormat({
        prefix,
        suffix,
        separator ,
        decimal,
        precision,
        min,
        max,
        nullValue,
        reverseFill,
        autofocus
    })
    return formatter.format(value)
}

export function numberFormatInFloat(value, {
    prefix = '',
    suffix = '',
    separator = ' ',
    decimal = '.',
    precision = 0,
    min = -Infinity,
    max = Infinity,
    nullValue = '',
    reverseFill = false,
    autofocus = false
} = {}) {
    let formatter = new NumberFormat({
        prefix,
        suffix,
        separator ,
        decimal,
        precision,
        min,
        max,
        nullValue,
        reverseFill,
        autofocus
    })
    return parseFloat(formatter.format(value).replaceAll(' ', ''))
}