import Vue from 'vue';
import Vuex from 'vuex';

export const SET_ZONES = "SET_ZONES";
export const SET_ZONE = "SET_ZONE";
export const SET_ZONES_LOADING = "SET_ZONES_LOADING";
export const SET_ZONE_ID = "SET_ZONE_ID";
export const SET_ZONE_POLYGON_DATA = "SET_ZONE_POLYGON_DATA";

Vue.use(Vuex);

const zoneStore = {
    state: {
        zones: [],
        zone: null,
        zonesLoading: false,
        zonePolygonData: {},
        zoneId: null
    },
    mutations: {
        [SET_ZONES](state, zones) {
            state.zones = zones
        },
        [SET_ZONE](state, zone) {
            state.zone = zone
        },
        [SET_ZONES_LOADING](state, zonesLoading) {
            state.zonesLoading = zonesLoading
        },
        [SET_ZONE_ID](state, zoneId) {
            state.zoneId = zoneId
        },
        [SET_ZONE_POLYGON_DATA](state, zonePolygonData) {
            state.zonePolygonData = zonePolygonData
        }
    },
    actions: {
        getZones({commit, dispatch}, regionId) {
            commit(SET_ZONES_LOADING, true)
            dispatch("resetMassivData")
            Vue.prototype.$axios.get(`zona/select/${regionId}`)
                .then(res => {
                    if (res && res.data && res.data.data) {
                        commit(SET_ZONES, res.data.data)
                        commit(SET_ZONES_LOADING, false)
                    }
                }, err => {
                    console.error(err);
                    commit(SET_ZONES_LOADING, false)
                });
        },
        setZoneId({commit, dispatch}, zoneId) {
            commit(SET_ZONE_ID, zoneId)
            if (zoneId) {
                dispatch("getPolygonByZoneId", zoneId)
            }
            else {
                dispatch("resetMassivAllFields")
            }
        },
        getPolygonByZoneId({commit}, zoneId) {
            Vue.prototype.$axios.get(`zona/${zoneId}`)
                .then(res => {
                    if (res && res.data && res.data.area) {
                        commit(SET_ZONE_POLYGON_DATA, res.data.area)
                    }
                    else {
                        commit(SET_ZONE_POLYGON_DATA, {})
                    }
                }, err => {
                    console.error(err);
                });
        },
        resetZones({commit}) {
            commit(SET_ZONES, [])
        },
        resetZoneAllFields({commit}) {
            commit(SET_ZONES, [])
            commit(SET_ZONE, null)
            commit(SET_ZONE_ID, null)
            commit(SET_ZONE_POLYGON_DATA, {})
        }
    },
    getters: {
        zonesList: state => {
            return state.zones
        }
    }
};

export default zoneStore;