import Vue from 'vue';
import Vuex from 'vuex';
import {
    addDataToIndexDb,
    deleteDataFromIndexDb, getAllDataFromIndexDb,
    getDataFromIndexDb,
    openIndexDB,
    updateDataOnIndexDb
} from "@/shared/indexedDB";
import moment from "moment/moment";

export const SET_ALL_SCREENSHOTS = "SET_ALL_SCREENSHOTS";

Vue.use(Vuex);

const screenshotStore = {
    state: {
        screenshotsDbName: "map_qr_code_screenshots_db",
        screenshotsObjStoreName: "screenshoots",
        screenshots: []
    },
    mutations: {
        [SET_ALL_SCREENSHOTS](state, allScreenshots) {
            state.screenshots = allScreenshots
        }
    },
    actions: {
        async initializeDB({ commit, state }) {
            try {
                const db = await openIndexDB(state.screenshotsDbName, 1, state.screenshotsObjStoreName);
                let screenshots = await getAllDataFromIndexDb(db, state.screenshotsObjStoreName)
                for (let i = 0; i < screenshots.length; i++) {
                    if(screenshots[i]?.createdDate && moment.unix(screenshots[i].createdDate).isBefore(moment().subtract(1, 'months'))) {
                        await deleteDataFromIndexDb(db, screenshots[i].id, state.screenshotsObjStoreName);
                    }
                }
                commit(SET_ALL_SCREENSHOTS, screenshots);
            } catch (error) {
                console.error("Failed to initialize DB:", error);
            }
        },
        async addScreenshotDataToDB({ commit, state }, newScreenshotData) {
            try {
                const db = await openIndexDB(state.screenshotsDbName, 1, state.screenshotsObjStoreName);
                let addedScreenshot = await addDataToIndexDb(db, newScreenshotData, state.screenshotsObjStoreName);
                let screenshots = await getAllDataFromIndexDb(db, state.screenshotsObjStoreName)
                commit(SET_ALL_SCREENSHOTS, screenshots);
                return addedScreenshot
            } catch (error) {
                console.error("Error adding new screenshot:", error);
            }
        },
        async updateScreenshotDataInDB({ commit, state }, screenshotData) {
            try {
                const db = await openIndexDB(state.screenshotsDbName, 1, state.screenshotsObjStoreName);
                let updatedScreenshot = await updateDataOnIndexDb(db, screenshotData, state.screenshotsObjStoreName);
                let screenshots = await getAllDataFromIndexDb(db, state.screenshotsObjStoreName)
                commit(SET_ALL_SCREENSHOTS, screenshots);
                return updatedScreenshot
            } catch (error) {
                console.error("Error updating screenshot:", error);
            }
        },
        async deleteScreenshotDataFromDB({ commit, state }, screenshotId) {
            try {
                const db = await openIndexDB(state.screenshotsDbName, 1, state.screenshotsObjStoreName);
                await deleteDataFromIndexDb(db, screenshotId, state.screenshotsObjStoreName);
                let screenshots = await getAllDataFromIndexDb(db, state.screenshotsObjStoreName)
                commit(SET_ALL_SCREENSHOTS, screenshots);
            } catch (error) {
                console.error("Error deleting screenshot:", error);
            }
        },
        async getScreenshotFromDB({ commit, state }, screenshotId) {
            try {
                const db = await openIndexDB(state.screenshotsDbName, 1, state.screenshotsObjStoreName);
                return await getDataFromIndexDb(db, screenshotId, state.screenshotsObjStoreName);
            } catch (error) {
                console.error("Error fetching screenshot:", error);
            }
        }
    },
    getters: {
        allScreenshots: (state) => {
            let uniqueSByMonths = [...new Set(state.screenshots.map(screenshot => screenshot.createdMonth))];
            let formattedScreenshots = {}
            uniqueSByMonths.forEach(uM => {
                formattedScreenshots[uM] = state.screenshots.filter(screenshot => screenshot.createdMonth === uM)
            })
            let formattedSClone = JSON.parse(JSON.stringify(formattedScreenshots))
            Object.keys(formattedScreenshots).forEach(nSKey => {
                let uniqDays = [...new Set(formattedSClone[nSKey].map(screenshot => screenshot.createdDay))];
                formattedScreenshots[nSKey] = {}
                uniqDays.forEach(uD => {
                    formattedScreenshots[nSKey][uD] = formattedSClone[nSKey].filter(screenshot => screenshot.createdDay === uD)
                })
            })
            return formattedScreenshots
        },
        getScreenshotById: (state) => (id) => state.screenshots.find((screenshot) => screenshot.id === id),
    }
};

export default screenshotStore;