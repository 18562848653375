<template>
  <div class="error-message-box" :class="{'relative-box': relative}" v-if="fieldModel.$error">
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('required') && !fieldModel.required">{{$t('common.errorMessage.required', {label: defaultLabel})}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('minLength') && !fieldModel.minLength">{{$t('common.errorMessage.minLength', {minLength: fieldModel.$params.minLength.min})}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('maxLength') && !fieldModel.maxLength">{{$t('common.errorMessage.maxLength', {maxLength: fieldModel.$params.maxLength.max})}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('minValue') && !fieldModel.minValue">{{$t('common.errorMessage.minValue', {minValue: fieldModel.$params.minValue.min})}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('maxValue') && !fieldModel.maxValue">{{$t('common.errorMessage.maxValue', {maxValue: fieldModel.$params.maxValue.max})}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('sameAsPassword') && !fieldModel.sameAsPassword">{{$t('common.errorMessage.sameAsPassword')}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('numeric') && !fieldModel.numeric">{{$t('common.errorMessage.numeric')}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('phoneNumber') && !fieldModel.phoneNumber">{{$t('common.errorMessage.phoneNumber')}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('location') && !fieldModel.location">{{$t('common.errorMessage.location')}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('exactLength') && !fieldModel.exactLength">{{$t('common.errorMessage.exactLength', {exactLength: fieldModel.$params.exactLength.value})}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('cadastreNumber') && !fieldModel.cadastreNumber">{{$t('common.errorMessage.cadastreNumber')}}</div>
    <div class="error-message-item" v-if="Object.keys(fieldModel).includes('tinLength') && !fieldModel.tinLength">{{$t('common.errorMessage.tinLength')}}</div>
    <div class="error-message-item" v-if="exactValue">{{$t('common.errorMessage.exactValue', {exactValue})}}</div>
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  name: "ErrorMessage",
  props: {
    fieldModel: {
      type: Object,
      default: () => {}
    },
    relative: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    exactValue: {
      type: String,
      default: null
    },
    filesMinLength: {
      type: Number,
      default: 0
    },
    filesMaxLength: {
      type: Number,
      default: 5
    },
  },
  computed: {
    defaultLabel() {
      return this.label ? this.label : i18n.t('common.errorMessage.defaultLabel')
    }
  }
}
</script>

<style scoped>

</style>
