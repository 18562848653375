import moment from "moment/moment";

export const openIndexDB = (dbName = "map_index_db", dbVersion = 1, objStoreName = "map_index_db_store") => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, dbVersion);

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(objStoreName)) {
                db.createObjectStore(objStoreName, { keyPath: "id" });
            }
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            reject("Database error: " + event.target.error);
        };
    });
};

export const addDataToIndexDb = (db, data, objStoreName = "map_index_db_store") => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([objStoreName], "readwrite");
        const store = transaction.objectStore(objStoreName);
        const cursorRequest = store.openCursor(null, "prev");
        cursorRequest.onsuccess = (event) => {
            const cursor = event?.target?.result;
            if (cursor) {
                const latestId = cursor.value.id;
                data.id = latestId + 1;
            } else {
                data.id = 1;
            }
            data.createdDate = moment().unix()
            data.createdDay = moment().date()
            data.createdMonth = moment().month()

            const addRequest = store.add(data);
            addRequest.onsuccess = () => resolve(data);
            addRequest.onerror = (event) => reject(event.target.error);
        };

        cursorRequest.onerror = (event) => reject(event.target.error);
    });
};

export const updateDataOnIndexDb = (db, data, objStoreName = "map_index_db_store") => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([objStoreName], "readwrite");
        const store = transaction.objectStore(objStoreName);

        data.updatedDate = moment().unix()
        data.updatedDay = moment().date()
        data.updatedMonth = moment().month()
        const request = store.put(data);

        request.onsuccess = () => resolve(data);
        request.onerror = (event) => reject(event.target.error);
    });
};

export const deleteDataFromIndexDb = (db, dataId, objStoreName = "map_index_db_store") => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([objStoreName], "readwrite");
        const store = transaction.objectStore(objStoreName);
        const request = store.delete(dataId);

        request.onsuccess = () => resolve(dataId);
        request.onerror = (event) => reject(event.target.error);
    });
};

export const getDataFromIndexDb = (db, dataId, objStoreName = "map_index_db_store") => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([objStoreName], "readonly");
        const store = transaction.objectStore(objStoreName);
        const request = store.get(dataId);

        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
    });
};

export const getAllDataFromIndexDb = (db, objStoreName = "map_index_db_store") => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction([objStoreName], "readonly");
        const store = transaction.objectStore(objStoreName);

        const request = store.getAll();

        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
    });
};
