import Vue from 'vue';
import Vuex from 'vuex';
export const SET_PROVINCES = "SET_PROVINCES";
export const SET_PROVINCE = "SET_PROVINCE";
export const SET_PROVINCES_LOADING = "SET_PROVINCES_LOADING";
export const SET_PROVINCE_POLYGON_DATA = "SET_PROVINCE_POLYGON_DATA";
export const SET_PROVINCE_ID = "SET_PROVINCE_ID";

Vue.use(Vuex);

const provinceStore = {
    // namespace: true,
    state: {
        provinces: [],
        province: null,
        provincesLoading: false,
        provincePolygonData: [],
        provinceId: null
    },
    mutations: {
        [SET_PROVINCES](state, provinces) {
            state.provinces = provinces
        },
        [SET_PROVINCE](state, province) {
            state.province = province
        },
        [SET_PROVINCES_LOADING](state, provincesLoading) {
            state.provincesLoading = provincesLoading
        },
        [SET_PROVINCE_POLYGON_DATA](state, provincePolygonData) {
            state.provincePolygonData = provincePolygonData
        },
        [SET_PROVINCE_ID](state, provinceId) {
            state.provinceId = provinceId
        }
    },
    actions: {
        getProvinces({commit, dispatch}) {
            commit(SET_PROVINCES_LOADING, true)
            dispatch("resetMFYData")
            dispatch("resetRegions")
            Vue.prototype.$axios.post('province/select', "")
                .then(res => {
                    if (res && res.data) {
                        commit(SET_PROVINCES, res.data)
                        commit(SET_PROVINCES_LOADING, false)
                    }
                }, err => {
                    console.error(err);
                    commit(SET_PROVINCES_LOADING, false)
                });
        },
        setProvinces({commit}, provinces) {
            commit(SET_PROVINCES, provinces)
        },
        setProvince({commit}, province) {
            commit(SET_PROVINCE, province)
        },
        setProvinceId({commit, dispatch}, {provinceId, isModeSimple}) {
            commit(SET_PROVINCE_ID, provinceId)
            dispatch("resetRegionAllFields")
            dispatch("resetMFYAllFields")
            dispatch("resetRoadAllFields")
            dispatch("resetZoneAllFields")
            dispatch("resetMassivAllFields")
            if(!isModeSimple) {
                if(provinceId) {
                    dispatch("getPolygonByProvinceId", provinceId)
                }
            }
        },
        getPolygonByProvinceId({commit}, provinceId) {
            Vue.prototype.$axios.get(`province/${provinceId}`)
                .then(res => {
                    if (res && res.data && res.data.area) {
                        commit(SET_PROVINCE_POLYGON_DATA, res.data.area)
                    }
                }, err => {
                    console.error(err);
                });
        },
        resetProvinces({commit}) {
            commit(SET_PROVINCES, [])
        },
        resetProvinceAllFields({commit}) {
            commit(SET_PROVINCES, [])
            commit(SET_PROVINCE, null)
            commit(SET_PROVINCE_ID, null)
            commit(SET_PROVINCE_POLYGON_DATA, [])
        }
    },
    getters: {
        provincesList: state => {
            return state.provinces
        }
    }
};

export default provinceStore;