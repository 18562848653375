import regionStore from "./region";
import provinceStore from "./province";
import cadastreStore from "./cadastre";
import mapTypeStore from "@/store/items/mapType";
import additionalCheckboxStore from "@/store/items/additionalCheckbox";
import settingsStore from "@/store/items/settings";
import zoneStore from "@/store/items/zone";
import massivStore from "@/store/items/massiv";
import mfyStore from "@/store/items/mfy";
import roadStore from "@/store/items/road";
import drawerStore from "@/store/items/drawer";
import screenshotStore from "@/store/items/screenshot";

const storeItems = {
    state: {
        ...provinceStore.state,
        ...regionStore.state,
        ...mfyStore.state,
        ...roadStore.state,
        ...zoneStore.state,
        ...massivStore.state,
        ...cadastreStore.state,
        ...mapTypeStore.state,
        ...additionalCheckboxStore.state,
        ...settingsStore.state,
        ...drawerStore.state,
        ...screenshotStore.state
    },
    mutations: {
        ...provinceStore.mutations,
        ...regionStore.mutations,
        ...mfyStore.mutations,
        ...roadStore.mutations,
        ...zoneStore.mutations,
        ...massivStore.mutations,
        ...cadastreStore.mutations,
        ...mapTypeStore.mutations,
        ...additionalCheckboxStore.mutations,
        ...settingsStore.mutations,
        ...drawerStore.mutations,
        ...screenshotStore.mutations
    },
    actions: {
        ...provinceStore.actions,
        ...regionStore.actions,
        ...mfyStore.actions,
        ...roadStore.actions,
        ...zoneStore.actions,
        ...massivStore.actions,
        ...cadastreStore.actions,
        ...mapTypeStore.actions,
        ...additionalCheckboxStore.actions,
        ...settingsStore.actions,
        ...drawerStore.actions,
        ...screenshotStore.actions
    },
    getters: {
        ...provinceStore.getters,
        ...regionStore.getters,
        ...mfyStore.getters,
        ...roadStore.getters,
        ...zoneStore.getters,
        ...massivStore.getters,
        ...cadastreStore.getters,
        ...mapTypeStore.getters,
        ...additionalCheckboxStore.getters,
        ...settingsStore.getters,
        ...drawerStore.getters,
        ...screenshotStore.getters
    }
}

export default storeItems