import Vue from 'vue';
import Vuex from 'vuex';

export const SET_ROAD_DATA = "SET_ROAD_DATA";
export const SET_ROAD = "SET_ROAD";
export const SET_ROAD_DATA_LOADING = "SET_ROAD_DATA_LOADING";
export const SET_ROAD_ID = "SET_ROAD_ID";
export const SET_MFY_ID = "SET_MFY_ID";
export const SET_ROAD_POLYGON_DATA = "SET_ROAD_POLYGON_DATA";
export const SET_HOMES_NUMBER_DATA = "SET_HOMES_NUMBER_DATA";

Vue.use(Vuex);

const roadStore = {
    state: {
        roadData: [],
        road: null,
        roadDataLoading: false,
        roadPolygonData: {},
        roadId: null,
        homesNumberData: []
    },
    mutations: {
        [SET_ROAD_DATA](state, roadData) {
            state.roadData = roadData
        },
        [SET_ROAD](state, road) {
            state.road = road
        },
        [SET_ROAD_DATA_LOADING](state, roadDataLoading) {
            state.roadDataLoading = roadDataLoading
        },
        [SET_ROAD_ID](state, roadId) {
            state.roadId = roadId
        },
        [SET_ROAD_POLYGON_DATA](state, roadPolygonData) {
            state.roadPolygonData = roadPolygonData
        },
        [SET_HOMES_NUMBER_DATA](state, homesNumberData) {
            state.homesNumberData = homesNumberData
        }
    },
    actions: {
        getRoadData({commit}, mfyId) {
            commit(SET_ROAD_DATA_LOADING, true)
            Vue.prototype.$axios.get(`road/list-by-mfy/${mfyId}`)
                .then(res => {
                    if (res && res.data) {
                        commit(SET_ROAD_DATA, res.data)
                        commit(SET_ROAD_DATA_LOADING, false)
                    }
                }, err => {
                    console.error(err);
                    commit(SET_ROAD_DATA_LOADING, false)
                });
        },
        setRoadId({commit, dispatch}, {roadId, mfyId}) {
            commit(SET_ROAD_ID, roadId)
            dispatch("getPolygonByRoadId", {roadId, mfyId})
        },
        getPolygonByRoadId({commit}, {roadId, mfyId}) {
            if (!roadId) {
                roadId = 0
            }
            Vue.prototype.$axios.get(`road/geom/list/?mfyId=${mfyId}&roadId=${roadId}`)
                .then(res => {
                    if (res && res.data && res.data.length > 0) {
                        commit(SET_ROAD_POLYGON_DATA, res.data)
                    } else {
                        commit(SET_ROAD_POLYGON_DATA, {})
                    }
                }, err => {
                    console.error(err);
                });
        },
        resetRoadData({commit}) {
            commit(SET_ROAD_DATA, [])
        },
        resetRoadAllFields({commit}) {
            commit(SET_ROAD_DATA, [])
            commit(SET_ROAD, null)
            commit(SET_ROAD_ID, null)
            commit(SET_MFY_ID, null)
            commit(SET_ROAD_POLYGON_DATA, {})
        }
    },
    getters: {
        roadDataList: state => {
            return state.roadData
        }
    }
};

export default roadStore;